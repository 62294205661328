<template>
  <div class="initial">
    <div class="initialtop">初始设置
      <el-button type="info" round @click="chushihua" style="background-color:rgb(86,119,34)">初始化</el-button>
    </div>

    <div class="initialmain">
      <div style="width:36%">
        <div class="initialfirst">
          <div>{{ title1 }}</div>
          <div>{{ tablist.scf }}min{{ tablist.scm }}s</div>
          <div @click="shezhi1(title1, id1, (time1 = tablist.scf), (time2 = tablist.scm))
            ">
            设置
          </div>
        </div>
        <div class="initialfirst">
          <div>{{ title2 }}</div>
          <div>{{ tablist.zcf }}min{{ tablist.zcm }}s</div>
          <div @click="shezhi1(title2, id2, (time1 = tablist.zcf), (time2 = tablist.zcm))
            ">
            设置
          </div>
        </div>
        <div class="initialfirst">
          <div>{{ title3 }}</div>
          <div>{{ tablist.jsf }}min{{ tablist.jsm }}s</div>
          <div @click="shezhi1(title3, id3, (time1 = tablist.jsf), (time2 = tablist.jsm))
            ">
            设置
          </div>
        </div>
        <!-- 加药时间  -->
        <div class="initialfirst">
          <div>{{ title4 }}</div>
          <div>{{ tablist.jyf }}min{{ tablist.jym }}s</div>
          <div @click="shezhi1(title4, id4, (time1 = tablist.jyf), (time2 = tablist.jym))
            ">
            设置
          </div>
        </div>
        <!-- 清洗等待时间 -->
        <div class="initialfirst">
          <div>{{ title5 }}</div>
          <div>{{ tablist.qxs }}h{{ tablist.qxf }}min</div>
          <div @click="shezhi1(title5, id5, (time1 = tablist.qxs), (time2 = tablist.qxf))
            ">
            设置
          </div>
        </div>
        <!-- 感应器等待时间： -->
        <div class="initialfirst">
          <div>{{ title6 }}</div>
          <div>{{ tablist.gys }}h{{ tablist.gyf }}min</div>
          <div @click="shezhi1(title6, id6, (time1 = tablist.gys), (time2 = tablist.gyf))
            ">
            设置
          </div>
        </div>
      </div>
      <div style="width:33%">
        <!-- 感应器检测延时时间： -->
        <div class="initialfirst">
          <div>{{ title7 }}</div>
          <div style="width:60px">{{ tablist.gym }}s</div>
          <div @click="shezhi1(title7, id7, (time1 = tablist.gym))">设置</div>
        </div>
        <!-- 冲洗延时开启时间 -->
        <div class="initialfirst">
          <div>{{ title8 }}</div>
          <div style="width:60px">{{ tablist.cxyskq }}s</div>
          <div @click="shezhi1(title8, id8, (time1 = tablist.cxyskq))">
            设置
          </div>
        </div>
        <!-- 排水延时关闭时间 -->
        <div class="initialfirst">
          <div>{{ title9 }}</div>
          <div style="width:60px">{{ tablist.psysgb }}s</div>
          <div @click="shezhi1(title9, id9, (time1 = tablist.psysgb))">
            设置
          </div>
        </div>
        <!-- 冲洗气缸开时间  -->
        <div class="initialfirst">
          <div>{{ title10 }}</div>
          <div style="width:60px">{{ tablist.cxqgk }}s</div>
          <div @click="shezhi1(title10, id10, (time1 = tablist.cxqgk))">
            设置
          </div>
        </div>
        <!-- 冲洗气缸关时间 -->
        <div class="initialfirst">
          <div>{{ title11 }}</div>
          <div style="width:60px">{{ tablist.cxqgg }}s</div>
          <div @click="shezhi1(title11, id11, (time1 = tablist.cxqgg))">
            设置
          </div>
        </div>
      </div>
      <div style="width:28%">
        <!--长度  -->
        <div class="initialfirst">
          <div style="width:120px">{{ title12 }}</div>
          <div style="width:90px">{{ tablist.cd }}cm</div>
          <div @click="shezhi2(title12, id12, (time1 = tablist.cd))">设置</div>
        </div>
        <!-- 宽度 -->
        <div class="initialfirst">
          <div style="width:120px">{{ title13 }}</div>
          <div style="width:90px">{{ tablist.kd }}cm</div>
          <div @click="shezhi2(title13, id13, (time1 = tablist.kd))">设置</div>
        </div>
        <!-- 填充高度填充高度 -->
        <div class="initialfirst">
          <div style="width:120px">{{ title14 }}</div>
          <div style="width:90px">{{ tablist.tcgd }}cm</div>
          <div @click="shezhi2(title14, id14, (time1 = tablist.tcgd))">
            设置
          </div>
        </div>
        <div class="initialfirst">
          <div style="width:120px">容量：</div>
          <div style="width:90px">{{ tablist.rl }}L</div>
        </div>
      </div>
    </div>
    <!-- 弹出框 设置时间 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false">
      <div class="shetime">
        <p>时间：</p>
        <div>
          <div v-show="hour">
            <el-input-number v-model="num" :min="0
              " :max="98989898989898999989898989899898989898989898989898989898989" label="描述文字"
              @keyup.enter.native="surebtn"></el-input-number>
            <span>时</span>
          </div>
          <div v-show="min">
            <el-input-number v-model="num1" :min="0
              " :max="98989898989898999989898989899898989898989898989898989898989" label="描述文字"
              @keyup.enter.native="surebtn"></el-input-number>
            <span>分</span>
          </div>
          <div v-show="sec">
            <el-input-number v-model="num2" :min="0
              " :max="98989898989898999989898989899898989898989898989898989898989" label="描述文字"
              @keyup.enter.native="surebtn"></el-input-number>
            <span>秒</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="surebtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹出框2  设置长度  -->
    <el-dialog :title="title0" :visible.sync="dialogVisible1" width="600px" :close-on-click-modal="false">
      <div style="font-size: 20px">
        <el-input-number v-model="num" :min="0" :max="10000" label="描述文字"
          @keyup.enter.native="surebtn1"></el-input-number>
        <span style="margin-left: 20px">cm</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="surebtn1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { details } from "../../api/detail/details";
import { chushi } from "../../api/equip/device";
export default {
  name: "settime",
  data() {
    return {
      num: "",
      num1: "",
      num2: "",
      title: "弹出框",
      title0: "弹出框2",
      dialogVisible: false,
      dialogVisible1: false,
      title1: "首次冲洗时间：",
      id1: 1,
      title2: "正常冲洗时间：",
      id2: 2,
      title3: "加水时间：",
      id3: 3,
      title4: "加药时间：",
      id4: 4,
      title5: "清洗等待时间：",
      id5: 5,
      title6: "感应器等待时间：",
      id6: 6,
      title7: "感应器检测延时时间：",
      id7: 7,
      title8: "冲洗延时开启时间：",
      id8: 8,
      title9: "排水延时关闭时间：",
      id9: 9,
      title10: "冲洗气缸开时间：",
      id10: 10,
      title11: "冲洗气缸关时间：",
      id11: 11,
      title12: "长度：",
      id12: 12,
      title13: "宽度：",
      id13: 13,
      title14: "填充高度：", 
      id14: 14, 
      // 分                
      min: true,
      // 时                     
      hour: true,
      // 秒    
      sec: true,
      // 月                    
      sheid: "",
      tablist: {},
      allid: "",
      sn: "",
    };
  },
  created() {
    //获取数据   
    details(this.$store.state.sheid).then((res) => {
      console.log("初始设置");
      console.log(res.initial[0]);
      this.tablist = res.initial[0];
      this.sn = res.details[0].sn;
    });
    //this.getDeails()   
  },
  methods: {
    //初始设置
    chushihua() {
      console.log('初始化')
      let str = {};
      str.sn = this.sn;
      console.log("首次冲洗时间");
      str.scf = 1;
      str.scm = 1;
      str.type = 1
      console.log(str);
      chushi(str).then((res) => {
        console.log('修改', str)
        if (res.resutlt == 0) {
          this.$message.success("设置成功,开始设置下一项.");
          details(this.$store.state.sheid).then((res) => {
            console.log("初始设置");
            console.log(res)
            console.log(res.initial[0]);
            this.tablist = res.initial[0];
            this.sn = res.details[0].sn;
            console.log("正常冲洗时间");
            let str = {};
            str.sn = this.sn;
            str.zcf = 2;
            str.zcm = 2;
            str.type = 2
            chushi(str).then((res) => {
              console.log('修改', str)
              if (res.resutlt == 0) {
                this.$message.success("设置成功,开始设置下一项.");
                details(this.$store.state.sheid).then((res) => {
                  console.log("初始设置");
                  console.log(res)
                  console.log(res.initial[0]);
                  this.tablist = res.initial[0];
                  this.sn = res.details[0].sn;
                  let str = {};
                  str.sn = this.sn;
                  console.log("加水时间");

                  str.jsf = 3
                  str.jsm = 3
                  str.type = 3
                  chushi(str).then((res) => {
                    console.log('修改', str)
                    if (res.resutlt == 0) {
                      this.$message.success("设置成功,开始设置下一项.");
                      details(this.$store.state.sheid).then((res) => {
                        console.log("初始设置");
                        console.log(res)
                        console.log(res.initial[0]);
                        this.tablist = res.initial[0];
                        this.sn = res.details[0].sn;
                        let str = {};
                        str.sn = this.sn;
                        console.log("加药时间");

                        str.jyf = 4
                        str.jym = 4
                        str.type = 4

                        chushi(str).then((res) => {
                          console.log('修改', str)
                          if (res.resutlt == 0) {
                            this.$message.success("设置成功,开始设置下一项.");
                            details(this.$store.state.sheid).then((res) => {
                              console.log("初始设置");
                              console.log(res)
                              console.log(res.initial[0]);
                              this.tablist = res.initial[0];
                              this.sn = res.details[0].sn;
                              let str = {};
                              str.sn = this.sn;
                              console.log("清洗等待时间");
                              str.qxf = 5
                              str.qxs = 5
                              str.type = 5
                              chushi(str).then((res) => {
                                console.log('修改', str)
                                if (res.resutlt == 0) {
                                  this.$message.success("设置成功,开始设置下一项.");
                                  details(this.$store.state.sheid).then((res) => {
                                    console.log("初始设置");
                                    console.log(res)
                                    console.log(res.initial[0]);
                                    this.tablist = res.initial[0];
                                    this.sn = res.details[0].sn;
                                    let str = {};
                                    str.sn = this.sn;
                                    console.log("感应器等待时间");

                                    str.gyf = 6
                                    str.gys = 6
                                    str.type = 6
                                    chushi(str).then((res) => {
                                      console.log('修改', str)
                                      if (res.resutlt == 0) {
                                        this.$message.success("设置成功,开始设置下一项.");
                                        details(this.$store.state.sheid).then((res) => {
                                          console.log("初始设置");
                                          console.log(res)
                                          console.log(res.initial[0]);
                                          this.tablist = res.initial[0];
                                          this.sn = res.details[0].sn;
                                          let str = {};
                                          str.sn = this.sn;
                                          console.log("感应器检测延时时间");

                                          str.gym = 7
                                          str.type = 7
                                          chushi(str).then((res) => {
                                            console.log('修改', str)
                                            if (res.resutlt == 0) {
                                              this.$message.success("设置成功,开始设置下一项.");
                                              details(this.$store.state.sheid).then((res) => {
                                                console.log("初始设置");
                                                console.log(res)
                                                console.log(res.initial[0]);
                                                this.tablist = res.initial[0];
                                                this.sn = res.details[0].sn;
                                                let str = {};
                                                str.sn = this.sn;
                                                console.log("冲洗延时开启时间");
                                                str.cxyskq = 8
                                                str.type = 8
                                                chushi(str).then((res) => {
                                                  console.log('修改', str)
                                                  if (res.resutlt == 0) {
                                                    this.$message.success("设置成功,开始设置下一项.");
                                                    details(this.$store.state.sheid).then((res) => {
                                                      console.log("初始设置");
                                                      console.log(res)
                                                      console.log(res.initial[0]);
                                                      this.tablist = res.initial[0];
                                                      this.sn = res.details[0].sn;
                                                      let str = {};
                                                      str.sn = this.sn;
                                                      console.log("排水延时关闭时间");

                                                      str.psysgb = 9
                                                      str.type = 9
                                                      chushi(str).then((res) => {
                                                        console.log('修改', str)
                                                        if (res.resutlt == 0) {
                                                          this.$message.success("设置成功,开始设置下一项.");
                                                          details(this.$store.state.sheid).then((res) => {
                                                            console.log("初始设置");
                                                            console.log(res)
                                                            console.log(res.initial[0]);
                                                            this.tablist = res.initial[0];
                                                            this.sn = res.details[0].sn;
                                                            let str = {};
                                                            str.sn = this.sn;
                                                            console.log("冲洗气缸开时间");

                                                            str.cxqgk = 10
                                                            str.type = 10
                                                            chushi(str).then((res) => {
                                                              console.log('修改', str)
                                                              if (res.resutlt == 0) {
                                                                this.$message.success("设置成功,开始设置下一项.");
                                                                details(this.$store.state.sheid).then((res) => {
                                                                  console.log("初始设置");
                                                                  console.log(res)
                                                                  console.log(res.initial[0]);
                                                                  this.tablist = res.initial[0];
                                                                  this.sn = res.details[0].sn;
                                                                  let str = {};
                                                                  str.sn = this.sn;
                                                                  console.log("冲洗气缸关时间");

                                                                  str.cxqgg = 11
                                                                  str.type = 11
                                                                  chushi(str).then((res) => {
                                                                    console.log('修改', str)
                                                                    if (res.resutlt == 0) {
                                                                      this.$message.success("设置成功,开始设置下一项.");
                                                                      details(this.$store.state.sheid).then((res) => {
                                                                        console.log("初始设置");
                                                                        console.log(res)
                                                                        console.log(res.initial[0]);
                                                                        this.tablist = res.initial[0];
                                                                        this.sn = res.details[0].sn;
                                                                        let str = {};
                                                                        str.sn = this.sn;
                                                                        str.cd = 9
                                                                        chushi(str).then((res) => {
                                                                          console.log('修改', str)
                                                                          if (res.resutlt == 0) {
                                                                            this.$message.success("设置成功,开始设置下一项.");
                                                                            details(this.$store.state.sheid).then((res) => {
                                                                              console.log("初始设置");
                                                                              console.log(res)
                                                                              console.log(res.initial[0]);
                                                                              this.tablist = res.initial[0];
                                                                              this.sn = res.details[0].sn;
                                                                              let str = {};
                                                                              str.sn = this.sn;
                                                                              str.kd = 8
                                                                              chushi(str).then((res) => {
                                                                                console.log('修改', str)
                                                                                if (res.resutlt == 0) {
                                                                                  this.$message.success("设置成功,开始设置下一项.");
                                                                                  details(this.$store.state.sheid).then((res) => {
                                                                                    console.log("初始设置");
                                                                                    console.log(res)
                                                                                    console.log(res.initial[0]);
                                                                                    this.tablist = res.initial[0];
                                                                                    this.sn = res.details[0].sn;
                                                                                    let str = {};
                                                                                    str.sn = this.sn;
                                                                                    str.tcgd = 7;
                                                                                    chushi(str).then((res) => {
                                                                                      console.log('修改', str)
                                                                                      if (res.resutlt == 0) {
                                                                                        this.$message.success("设置成功");
                                                                                        details(this.$store.state.sheid).then((res) => {
                                                                                          console.log("初始设置");
                                                                                          console.log(res)
                                                                                          console.log(res.initial[0]);
                                                                                          this.tablist = res.initial[0];
                                                                                          this.sn = res.details[0].sn;
                                                                                        });
                                                                                        console.log(res.initial[0])
                                                                                        this.dialogVisible = false;
                                                                                      } else {
                                                                                        this.$message.error(res.message);
                                                                                      }
                                                                                    });
                                                                                  });
                                                                                  console.log(res.initial[0])
                                                                                  this.dialogVisible = false;
                                                                                } else {
                                                                                  this.$message.error(res.message);
                                                                                }
                                                                              });
                                                                            });
                                                                            console.log(res.initial[0])
                                                                            this.dialogVisible = false;
                                                                          } else {
                                                                            this.$message.error(res.message);
                                                                          }
                                                                        });

                                                                      });
                                                                      console.log(res.initial[0])
                                                                      this.dialogVisible = false;
                                                                    } else {
                                                                      this.$message.error(res.message);
                                                                    }
                                                                  });
                                                                });
                                                                console.log(res.initial[0])
                                                                this.dialogVisible = false;
                                                              } else {
                                                                this.$message.error(res.message);
                                                              }
                                                            });
                                                          });
                                                          console.log(res.initial[0])
                                                          this.dialogVisible = false;
                                                        } else {
                                                          this.$message.error(res.message);
                                                        }
                                                      });
                                                    });
                                                    console.log(res.initial[0])
                                                    this.dialogVisible = false;
                                                  } else {
                                                    this.$message.error(res.message);
                                                  }
                                                });
                                              });
                                              console.log(res.initial[0])
                                              this.dialogVisible = false;
                                            } else {
                                              this.$message.error(res.message);
                                            }
                                          });
                                        });
                                        console.log(res.initial[0])
                                        this.dialogVisible = false;
                                      } else {
                                        this.$message.error(res.message);
                                      }
                                    });
                                  });
                                  console.log(res.initial[0])
                                  this.dialogVisible = false;
                                } else {
                                  this.$message.error(res.message);
                                }
                              });
                            });
                            console.log(res.initial[0])
                            this.dialogVisible = false;
                          } else {
                            this.$message.error(res.message);
                          }
                        });
                      });
                      console.log(res.initial[0])
                      this.dialogVisible = false;
                    } else {
                      this.$message.error(res.message);
                    }
                  });
                });
                console.log(res.initial[0])
                this.dialogVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          });
          console.log(res.initial[0])
          this.dialogVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
      // let str = {};
      // str.sn = this.sn;
      // str.cd = 6
      // chushi(str).then((res) => {
      //   console.log('修改', str)
      //   if (res.resutlt == 0) {
      //     this.$message.success("设置成功");
      //     details(this.$store.state.sheid).then((res) => {
      //       console.log("初始设置");
      //       console.log(res)
      //       console.log(res.initial[0]);
      //       this.tablist = res.initial[0];
      //       this.sn = res.details[0].sn;
      //       let str = {};
      //       str.sn = this.sn;
      //       str.kd = 7
      //       chushi(str).then((res) => {
      //         console.log('修改', str)
      //         if (res.resutlt == 0) {
      //           this.$message.success("设置成功");
      //           details(this.$store.state.sheid).then((res) => {
      //             console.log("初始设置");
      //             console.log(res)
      //             console.log(res.initial[0]);
      //             this.tablist = res.initial[0];
      //             this.sn = res.details[0].sn;
      //             let str = {};
      //             str.sn = this.sn;
      //             str.tcgd = 8;
      //             chushi(str).then((res) => {
      //               console.log('修改', str)
      //               if (res.resutlt == 0) {
      //                 this.$message.success("设置成功");
      //                 details(this.$store.state.sheid).then((res) => {
      //                   console.log("初始设置");
      //                   console.log(res)
      //                   console.log(res.initial[0]);
      //                   this.tablist = res.initial[0];
      //                   this.sn = res.details[0].sn;
      //                 });
      //                 console.log(res.initial[0])
      //                 this.dialogVisible = false;
      //               } else {
      //                 this.$message.error(res.message);
      //               }
      //             });
      //           });
      //           console.log(res.initial[0])
      //           this.dialogVisible = false;
      //         } else {
      //           this.$message.error(res.message);
      //         }
      //       });
      //     });
      //     console.log(res.initial[0])
      //     this.dialogVisible = false;
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
    // 获取数据
    // getDeails(){
    //   details(this.$store.state.sheid).then((res) => {
    //   console.log("初始设置");
    //   console.log(res.initial[0]);
    //   this.tablist = res.initial[0];
    //   this.sn = res.details[0].sn;
    //  });
    // },
    shezhi1(title, id, time1, time2) {
      console.log(title);
      console.log(id);
      console.log(time1);
      console.log(time2);
      this.allid = id;
      this.num = "";
      this.num1 = "";
      this.num2 = "";
      this.hour = true;
      this.sec = true;
      this.min = true;
      this.dialogVisible = true;
      this.title = title;
      if (id == "1" || id == "2" || id == "3" || id == "4") {
        this.hour = false;
        this.sheid = id;
        // console.log(this.sheid);
        // 分钟，秒
        this.num1 = time1;
        this.num2 = time2;
      } else if (id == "5" || id == "6") {
        this.sec = false;
        this.sheid = id;
        this.num = time1;
        this.num1 = time2;
        // 时 num，分num1  num2
        // console.log(this.sheid);
      } else if (
        id == "7" ||
        id == "8" ||
        id == "9" ||
        id == "10" ||
        id == "11"
      ) {
        // 秒
        this.hour = false;
        this.min = false;
        this.sheid = id;
        this.num2 = time1;
        console.log(this.sheid);
      }
      console.log(this.sheid);
      //this.getDeails()
    },
    // 设置2
    shezhi2(title, id, time1) {
      console.log(title, id);
      this.dialogVisible1 = true;
      this.title0 = "设置" + title;
      this.num = time1;
      this.allid = id;
    },
    // 点击确定
    surebtn() {
      console.log(this.allid);
      if (this.num > 24) {
        this.$message.error("不能超过24小时");
      } else if (this.num1 > 60) {
        this.$message.error("不能超过60分钟");
      } else if (this.num2 > 60) {
        this.$message.error("不能超过60秒");
      } else {
        let str = {};
        if (this.allid == 1) {
          console.log("首次冲洗时间");

          str.scf = this.num1;
          str.scm = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 2) {
          console.log("正常冲洗时间");

          str.zcf = this.num1;
          str.zcm = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 3) {
          console.log("加水时间");

          str.jsf = this.num1;
          str.jsm = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 4) {
          console.log("加药时间");

          str.jyf = this.num1;
          str.jym = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 5) {
          console.log("清洗等待时间");
          str.qxf = this.num1;
          str.qxs = this.num;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 6) {
          console.log("感应器等待时间");

          str.gyf = this.num1;
          str.gys = this.num;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 7) {
          console.log("感应器检测延时时间");

          str.gym = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 8) {
          console.log("冲洗延时开启时间");
          str.cxyskq = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 9) {
          console.log("排水延时关闭时间");

          str.psysgb = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 10) {
          console.log("冲洗气缸开时间");

          str.cxqgk = this.num2;
          str.type = this.allid
          console.log(str);
        } else if (this.allid == 11) {
          console.log("冲洗气缸关时间");

          str.cxqgg = this.num2;
          str.type = this.allid
          console.log(str);
        }
        str.sn = this.sn;
        chushi(str).then((res) => {
          console.log('修改', str)
          this.dialogVisible = false;
          if (res.resutlt == 0) {
            this.$message.success("设置成功");
            details(this.$store.state.sheid).then((res) => {
              console.log("初始设置");
              console.log(res)
              console.log(res.initial[0]);
              this.tablist = res.initial[0];
              this.sn = res.details[0].sn;
            });
            console.log(res.initial[0])
          } else {
            this.$message.error(res.message);
          }
        });
      }
      // this.dialogVisible = false; 
    },
    surebtn1() {
      console.log(this.allid);
      let str = {};
      if (this.allid == 12) {
        console.log("长度");
        str.cd = this.num;
        console.log(str);
      } else if (this.allid == 13) {
        console.log("长度");
        str.kd = this.num;
        console.log(str);
      } else if (this.allid == 14) {
        console.log("填充高度");
        str.tcgd = this.num;
        console.log(str);
      }
      str.sn = this.sn;
      console.log('查看', str);
      chushi(str).then((res) => {
        console.log(res);
        if (res.resutlt == 0) {
          details(this.$store.state.sheid).then((res) => {
            console.log("初始设置");
            console.log(res.initial[0]);
            this.tablist = res.initial[0];
            this.sn = res.details[0].sn;
          });
          this.$message.success("设置成功");
          this.dialogVisible1 = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
* {
  box-sizing: border-box;
}

.initial {
  height: 413px;
  background: white;
  padding: 40px;

  .initialtop {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .initialmain {
    >div {
      width: 33%;
      align-items: center
    }

    display: flex;
    justify-content: space-between;
  }

  .initialfirst {
    //width:400px;
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    //justify-content: center;
    div:nth-of-type(1) {
      width: 240px;
      text-align: right;
    }

    div:nth-of-type(2) {
      width: 120px;
      text-align: left;
    }

    div:nth-of-type(3) {
      margin-left: 25px;
      color: rgba(68, 131, 244, 100);
      cursor: pointer;
      text-align: left;
      width: 60px
    }
  }

  .shetime {
    font-size: 24px;
    display: flex;

    p {
      margin-right: 20px;
    }

    >div>div {
      margin-top: 20px;

      span {
        margin-left: 20px;
      }
    }

    /deep/.el-input__inner {
      font-size: 20px;
    }
  }

  /deep/.el-dialog__title {
    font-size: 28px;
    font-weight: 600;
  }

  /deep/.el-button {
    font-size: 20px;
  }
}
</style>
